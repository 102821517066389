import Default from '../pics/nets/default.svg';
import Ire5 from '../pics/nets/5ire.svg';
import Abey from '../pics/nets/abey.svg';
import Abstract from '../pics/nets/abstract.svg';
import AILayer from '../pics/nets/ailayer.svg';
import Algorand from '../pics/nets/algorand.svg';
import Ancient8 from '../pics/nets/ancient8.svg';
import Aptos from '../pics/nets/aptos.svg';
import Arbitrum from '../pics/nets/arbitrum-one.svg';
import ArbitrumNova from '../pics/nets/arbitrum-nova.svg';
import Areon from '../pics/nets/areon.svg';
import Artela from '../pics/nets/artela.svg';
import Astar from '../pics/nets/astar.svg';
import AstarZkEVM from '../pics/nets/astar-zkevm.svg';
import Aurora from '../pics/nets/aurora.svg';
import Avalanche from '../pics/nets/avalanche.svg';
import B2 from '../pics/nets/b2.svg';
import Base from '../pics/nets/base.svg';
import Berachain from '../pics/nets/berachain.svg';
import Bitcoin from '../pics/nets/bitcoin.svg';
import Bitgert from '../pics/nets/bitgert.svg';
import Bitlayer from '../pics/nets/bitlayer.svg';
import Blast from '../pics/nets/blast.svg';
import BOB from '../pics/nets/bob.svg';
import Boba from '../pics/nets/boba.svg';
import BSC from '../pics/nets/bsc.svg';
import BSV from '../pics/nets/bsv.svg';
import Caduceus from '../pics/nets/caduceus.svg';
import Canto from '../pics/nets/canto.svg';
import Cardano from '../pics/nets/cardano.svg';
import Celo from '../pics/nets/celo.svg';
import Coinflux from '../pics/nets/coinflux.svg';
import CoreDAO from '../pics/nets/core-dao.svg';
import Cronos from '../pics/nets/cronos.svg';
import Cyber from '../pics/nets/cyber.svg';
import DBKchain from '../pics/nets/dbk-chain.svg';
import Degen from '../pics/nets/degen.svg';
import DegenChain from '../pics/nets/degen-chain.svg';
import DFKChain from '../pics/nets/dfk-chain.svg';
import DOSchain from '../pics/nets/dos-chain.svg';
import Eluvio from '../pics/nets/eluvio.svg';
import Energi from '../pics/nets/energi.svg';
import ENULS from '../pics/nets/enuls.svg';
import EOS from '../pics/nets/eos-evm.svg';
import Ethereum from '../pics/nets/ethereum.svg';
import EthereumClassic from '../pics/nets/ethereum-classic.svg';
import EvmOs from '../pics/nets/evmos.svg';
import Fantom from '../pics/nets/fantom.svg';
import Flare from '../pics/nets/flare.svg';
import Flow from '../pics/nets/flow.svg';
import Fraxtal from '../pics/nets/fraxtal.svg';
import Fuse from '../pics/nets/fuse.svg';
import Gatechain from '../pics/nets/gatechain.svg';
import Gnosis from '../pics/nets/gnosis.svg';
import Godwoken from '../pics/nets/godwoken.svg';
import Gravity from '../pics/nets/gravity.svg';
import Ham from '../pics/nets/ham.svg';
import Harmony from '../pics/nets/harmony.svg';
import Heco from '../pics/nets/heco.svg';
import Hedera from '../pics/nets/hedera.svg';
import Hychain from '../pics/nets/hychain.svg';
import Icp from '../pics/nets/icp.svg';
import Immutable from '../pics/nets/immutable-x.svg';
import ImmutableZkEVM from '../pics/nets/immutable-zkevm.svg';
import Inevm from '../pics/nets/inevm.svg';
import Iotex from '../pics/nets/iotex.svg';
import Kakarot from '../pics/nets/kakarot.svg';
import KAVA from '../pics/nets/kava.svg';
import Klaytn from '../pics/nets/klaytn.svg';
import Kroma from '../pics/nets/kroma.svg';
import Kusama from '../pics/nets/kusama.svg';
import LayerEdge from '../pics/nets/layeredge.svg';
import Layerzero from '../pics/nets/layer-zero.svg';
import Liberty from '../pics/nets/liberty-2-x.svg';
import LightLink from '../pics/nets/lightlink.svg';
import Linea from '../pics/nets/linea.svg';
import Lisk from '../pics/nets/lisk.svg';
import Loopring from '../pics/nets/loopring.svg';
import Manta from '../pics/nets/manta.svg';
import Mantle from '../pics/nets/mantle.svg';
import Merlin from '../pics/nets/merlin.svg';
import Metis from '../pics/nets/metis.svg';
import Mint from '../pics/nets/mint.svg';
import Mode from '../pics/nets/mode.svg';
import Moonbeam from '../pics/nets/moonbeam.svg';
import Morph from '../pics/nets/morph.svg';
import Multiversx from '../pics/nets/multiversx.svg';
import Near from '../pics/nets/near.svg';
import NeonEVM from '../pics/nets/neon-evm.svg';
import Neutron from '../pics/nets/neutron.svg';
import OKT from '../pics/nets/okt-chain.svg';
import Ontology from '../pics/nets/ontology.svg';
import Opbnb from '../pics/nets/opbnb.svg';
import Optimism from '../pics/nets/optimism.svg';
import Palm from '../pics/nets/palm.svg';
import Platon from '../pics/nets/platon.svg';
import Polkadot from '../pics/nets/polkadot.svg';
import Polygon from '../pics/nets/polygon.svg';
import Pulsechain from '../pics/nets/pulsechain.svg';
import Qchain from '../pics/nets/qchain.svg';
import Quadrans from '../pics/nets/quadrans.svg';
import RariChain from '../pics/nets/rari-chain.svg';
import Redlight from '../pics/nets/redlight.svg';
import Redstone from '../pics/nets/redstone.svg';
import Rollux from '../pics/nets/rollux.svg';
import Ronin from '../pics/nets/ronin.svg';
import Rootstock from '../pics/nets/rootstock.svg';
import Sanko from '../pics/nets/sanko.svg';
import SatoshiVM from '../pics/nets/satoshivm.svg';
import Scroll from '../pics/nets/scroll.svg';
import Secret from '../pics/nets/secret.svg';
import Sei from '../pics/nets/sei.svg';
import Shibarium from '../pics/nets/shibarium.svg';
import Shimmer from '../pics/nets/shimmer.svg';
import Solana from '../pics/nets/solana.svg';
import Soneium from '../pics/nets/soneium.svg';
import Starknet from '../pics/nets/starknet.svg';
import Sui from '../pics/nets/sui.svg';
import Syscoin from '../pics/nets/syscoin.svg';
import Taiko from '../pics/nets/taiko.svg';
import Telos from '../pics/nets/telos.svg';
import Tezos from '../pics/nets/tezos.svg';
import Thundercore from '../pics/nets/thundercore.svg';
import Ton from '../pics/nets/ton.svg';
import Tron from '../pics/nets/tron.svg';
import Ultron from '../pics/nets/ultron.svg';
import Unichain from '../pics/nets/unichain.svg';
import Vechain from '../pics/nets/vechain.svg';
import Velas from '../pics/nets/velas.svg';
import Viction from '../pics/nets/viction.svg';
import Vizing from '../pics/nets/vizing.svg';
import WAX from '../pics/nets/wax.svg';
import WEMIX from '../pics/nets/wemix.svg';
import XAI from '../pics/nets/xai.svg';
import Xlayer from '../pics/nets/x-layer.svg';
import XRPLedger from '../pics/nets/xrp-ledger.svg';
import Zetachain from '../pics/nets/zetachain.svg';
import Zilliqa from '../pics/nets/zilliqa.svg';
import Zircuit from '../pics/nets/zircuit.svg';
import Zkfair from '../pics/nets/zkfair.svg';
import ZklinkNova from '../pics/nets/zklink-nova.svg';
import Zksync from '../pics/nets/zksync.svg';
import Zora from '../pics/nets/zora.svg';
import Coingecko from '../pics/socials/coingecko.svg';
import Coinmarketcap from '../pics/socials/coinmarketcap.svg';
import Discord from '../pics/socials/discord.svg';
import Facebook from '../pics/socials/facebook.svg';
import Github from '../pics/socials/github.svg';
import Instagram from '../pics/socials/instagram.svg';
import Linkedin from '../pics/socials/linkedin.svg';
import Medium from '../pics/socials/medium.svg';
import Reddit from '../pics/socials/reddit.svg';
import Telegram from '../pics/socials/telegram.svg';
import Tiktok from '../pics/socials/tiktok.svg';
import Twitter from '../pics/socials/twitter.svg';
import Web from '../pics/socials/web.svg';
import Youtube from '../pics/socials/youtube.svg';

const icons = {
  'default': Default,
  '5ire': Ire5,
  'ABEY': Abey,
  'Abstract': Abstract,
  'AILayer': AILayer,
  'Algorand': Algorand,
  'Ancient8': Ancient8,
  'Aptos': Aptos,
  'Arbitrum One': Arbitrum,
  'Arbitrum Nova': ArbitrumNova,
  'Areon': Areon,
  'Artela': Artela,
  'Astar': Astar,
  'Astar zkEVM': AstarZkEVM,
  'Aurora': Aurora,
  'Avalanche': Avalanche,
  'B2': B2,
  'Base': Base,
  'Berachain': Berachain,
  'Bitcoin': Bitcoin,
  'Bitgert': Bitgert,
  'Bitlayer': Bitlayer,
  'Blast': Blast,
  'BOB': BOB,
  'Boba': Boba,
  'BSC': BSC,
  'BSV': BSV,
  'Caduceus': Caduceus,
  'Canto': Canto,
  'Cardano': Cardano,
  'Celo': Celo,
  'Coinflux': Coinflux,
  'Core DAO': CoreDAO,
  'Cronos': Cronos,
  'Cyber': Cyber,
  'DBK chain': DBKchain,
  'Degen': Degen,
  'Degen Chain': DegenChain,
  'DFK Chain': DFKChain,
  'DOS chain': DOSchain,
  'Eluvio': Eluvio,
  'Energi': Energi,
  'ENULS': ENULS,
  'EOS EVM': EOS,
  'Ethereum': Ethereum,
  'Ethereum Classic': EthereumClassic,
  'EvmOs': EvmOs,
  'Fantom': Fantom,
  'Flare': Flare,
  'Flow': Flow,
  'Fraxtal': Fraxtal,
  'Fuse': Fuse,
  'GateChain': Gatechain,
  'Gnosis': Gnosis,
  'Godwoken': Godwoken,
  'Gravity': Gravity,
  'Ham': Ham,
  'Harmony': Harmony,
  'Heco': Heco,
  'Hedera': Hedera,
  'Hychain': Hychain,
  'ICP': Icp,
  'Immutable X': Immutable,
  'Immutable zkEVM': ImmutableZkEVM,
  'inEVM': Inevm,
  'IoTeX': Iotex,
  'Kakarot': Kakarot,
  'KAVA': KAVA,
  'Klaytn': Klaytn,
  'Kroma': Kroma,
  'Kusama': Kusama,
  'LayerEdge': LayerEdge,
  'Layer Zero': Layerzero,
  'Liberty 2.X': Liberty,
  'LightLink': LightLink,
  'Linea': Linea,
  'Lisk': Lisk,
  'Loopring': Loopring,
  'Manta': Manta,
  'Mantle': Mantle,
  'Merlin': Merlin,
  'Metis': Metis,
  'Mint': Mint,
  'Mode': Mode,
  'Moonbeam': Moonbeam,
  'Morph': Morph,
  'MultiversX': Multiversx,
  'NEAR': Near,
  'Neon EVM': NeonEVM,
  'Neutron': Neutron,  
  'OKT Chain': OKT,
  'Ontology': Ontology,
  'opBNB': Opbnb,
  'Optimism': Optimism,
  'Palm': Palm,
  'PlatON': Platon,
  'Polkadot': Polkadot,
  'Polygon': Polygon,
  'Polygon zkEVM': Polygon,
  'Pulsechain': Pulsechain,
  'Qchain': Qchain,
  'Quadrans': Quadrans,
  'RARI chain': RariChain,
  'Redlight': Redlight,
  'Redstone': Redstone,
  'Rollux': Rollux,
  'Ronin': Ronin,
  'Rootstock': Rootstock,
  'Sanko': Sanko,
  'SatoshiVM': SatoshiVM,
  'Scroll': Scroll,
  'Secret': Secret,
  'Sei': Sei,
  'Shibarium': Shibarium,
  'Shimmer': Shimmer,
  'Solana': Solana,
  'Soneium': Soneium,
  'Starknet': Starknet,
  'SUI': Sui,
  'Syscoin': Syscoin,
  'Taiko': Taiko,
  'Telos': Telos,
  'Tezos': Tezos,
  'ThunderCore': Thundercore,
  'TON': Ton,
  'Tron': Tron,
  'Ultron': Ultron,
  'Unichain': Unichain,
  'VeChain': Vechain,
  'Velas': Velas,
  'Viction': Viction,
  'Vizing': Vizing,
  'WAX': WAX,
  'WEMIX': WEMIX,
  'XAI': XAI,
  'X layer': Xlayer,
  'XRP Ledger': XRPLedger,
  'ZetaChain': Zetachain,
  'Zilliqa': Zilliqa,
  'Zircuit': Zircuit,
  'ZKFair': Zkfair,
  'zkLink Nova': ZklinkNova,
  'zkSync': Zksync,
  'Zora': Zora,
  'Coingecko': Coingecko,
  'Coinmarketcap': Coinmarketcap,
  'Discord': Discord,
  'Facebook': Facebook,
  'Github': Github,
  'Instagram': Instagram,
  'Linkedin': Linkedin,
  'Medium': Medium,
  'Reddit': Reddit,
  'Telegram': Telegram,
  'Tiktok': Tiktok,
  'Twitter': Twitter,
  'Web': Web,
  'Youtube': Youtube
}

export default icons;